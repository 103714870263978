import React from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";

import Routes from "./view/Routes";
import { BrowserRouter } from "react-router-dom";
import { Suspense } from "react";
import Loader from "./view/component/UI/Loader";
import UiAlert from "./view/component/UI/UiAlert";

function App() {
  return (
    <>
      <HelmetProvider>
        <Helmet titleTemplate="%s | Sarthi" defaultTitle="Sarthi" />

        <BrowserRouter>
          <Suspense fallback={<Loader />}>
            <UiAlert />
            <Routes />
          </Suspense>
        </BrowserRouter>
      </HelmetProvider>
    </>
  );
}

export default App;
