import axios from "../utils/axios";

const services = {
  login: (payload) => axios.post("/login", payload),
  getUserDetails: (token) =>
    axios.get("/user", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),

  getUserLocation: (uid) => axios.get(`/user/${uid}/locations`),

  getUserImages: (uid) => axios.get(`/user/${uid}/images`),

  lockPhone: () => axios.post("/device", { task: "lock" }),
  unlockPhone: () => axios.post("/device", { task: "unlock" }),

  generateTokens: (payload) => axios.post("/admin/token", payload),

  getAdminActivationCodes: (startAfter, limit, filter, phoneNumber, duration) =>
    axios.get("/admin/token", {
      params: { startAfter, limit, filter, phoneNumber, type: duration },
    }),

  getAdminUserLockUnlock: (startAfter, limit, phone) =>
    axios.get(
      startAfter
        ? `/admin/token/users?startAfter=${startAfter}&limit=${limit}&phoneNumber=${phone}`
        : `/admin/token/users?limit=${limit}&phoneNumber=${phone}`
    ),

  getAdminTokensAggregations: () => axios.get("/admin/token/aggr"),

  lockPhoneByAdmin: (payload) => axios.post("/admin/device", payload),

  refreshToken: () => axios.get("/refresh-token"),

  getPaymentOrder: (type) =>
    axios.get("/payment/create-order", { params: { type } }),

  verifyPayment: (payload) => axios.post("/payment/verify", payload),

  disableToken: (tokenId, action) =>
    axios.post("/admin/disableToken", { tokenId, action }),
};

export default services;
