import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { actions as uiActions, ALERT_TYPES } from "./uiSlice";

const userSelectors = {
  getProfileInfo: (state) => state.user.profile,
  getUserUi: (state) => state.user.ui,
  getUserLocation: (state) => state.user.location,
  getUserImages: (state) => state.user.images,
};

const fetchUserProfile = createAsyncThunk(
  "user/fetchProfile",
  async (_, { extra: api }) => {
    const { data } = await api.getUserDetails();
    return data.data;
  }
);

const lockPhone = createAsyncThunk(
  "user/lockPhone",
  async (_, { extra: api, dispatch, rejectWithValue }) => {
    try {
      await api.lockPhone();
      dispatch(
        uiActions.showAlert({
          type: ALERT_TYPES.SUCCESS,
          message: "Locked phone successfully!",
        })
      );
    } catch (error) {
      return rejectWithValue("Couldn't lock phone!");
    }
  }
);

const unlockPhone = createAsyncThunk(
  "user/unlockPhone",
  async (_, { extra: api, dispatch, rejectWithValue }) => {
    try {
      await api.unlockPhone();
      dispatch(
        uiActions.showAlert({
          type: ALERT_TYPES.SUCCESS,
          message: "Unlocked phone successfully!",
        })
      );
    } catch (error) {
      return rejectWithValue("Couldn't unlock phone!");
    }
  }
);

const fetchLocation = createAsyncThunk(
  "user/fetchLocation",
  async ({ uid }, { extra: api }) => {
    const { data } = await api.getUserLocation(uid);
    return data.data;
  }
);

const fetchImages = createAsyncThunk(
  "user/fetchImages",
  async ({ uid }, { extra: api }) => {
    const { data } = await api.getUserImages(uid);
    return data.data;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    profile: null,
    location: null,
    images: null,
    ui: {
      fetchUserProfile: {},
      lockPhone: {},
      unlockPhone: {},
      fetchLocation: {},
      fetchImages: {},
    },
  },
  extraReducers: {
    [fetchUserProfile.pending]: (state) => {
      state.ui = {
        fetchUserProfile: { loading: true },
        lockPhone: {},
        unlockPhone: {},
        fetchLocation: {},
        fetchImages: {},
      };
    },
    [fetchUserProfile.fulfilled]: (state, { payload }) => {
      state.ui.fetchUserProfile.loading = false;
      state.profile = {
        ...payload,
      };
    },
    [fetchUserProfile.rejected]: (state) => {
      state.ui.fetchUserProfile.loading = false;
    },
    [lockPhone.pending]: (state) => {
      state.ui.lockPhone.loading = true;
      state.ui.lockPhone.error = false;
    },
    [lockPhone.fulfilled]: (state) => {
      state.ui.lockPhone.loading = false;
      state.profile.locked = true;
    },
    [lockPhone.rejected]: (state) => {
      state.ui.lockPhone.loading = false;
      state.ui.lockPhone.error = true;
    },
    [unlockPhone.pending]: (state) => {
      state.ui.unlockPhone.loading = true;
      state.ui.unlockPhone.error = false;
    },
    [unlockPhone.fulfilled]: (state) => {
      state.ui.unlockPhone.loading = false;
      state.profile.locked = false;
    },
    [unlockPhone.rejected]: (state) => {
      state.ui.unlockPhone.loading = false;
      state.ui.unlockPhone.error = true;
    },
    [fetchLocation.pending]: (state) => {
      state.ui.fetchLocation.loading = true;
    },
    [fetchLocation.fulfilled]: (state, { payload }) => {
      state.ui.fetchLocation.loading = false;
      state.location = payload;
    },
    [fetchLocation.rejected]: (state) => {
      state.ui.fetchLocation.loading = false;
    },
    [fetchImages.pending]: (state) => {
      state.ui.fetchImages.loading = true;
    },
    [fetchImages.fulfilled]: (state, { payload }) => {
      state.ui.fetchImages.loading = false;
      state.images = payload;
    },
    [fetchImages.rejected]: (state) => {
      state.ui.fetchImages.loading = false;
    },
  },
});

export default userSlice.reducer;

const userActions = { ...userSlice.actions };

export {
  userActions,
  userSelectors,
  fetchUserProfile,
  lockPhone,
  unlockPhone,
  fetchLocation,
  fetchImages,
};
