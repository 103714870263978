import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import { Container } from "../UI/styledItems";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { logout } from "../../../application/reducers/authSlice";
import linkStartImg from "../../Assets/admin/img.svg";

const useStyles = makeStyles((theme) => ({
  navbar: {
    fontFamily: "'Poppins', sans-serif",
    background: "#1C232D",
    padding: "0 !important",
    position: "relative",
    zIndex: 200,
  },
  navbarCont: {
    height: "4.5rem",
    display: "flex",
    padding: "0 15px",
    alignItems: "center",
    position: "relative",
  },
  brandLogoLink: {
    marginRight: "0.6rem",
    [theme.breakpoints.down(430)]: {
      marginRight: "0.2rem",
    },
    "& img": {
      height: "4.25rem",
    },
  },
  menuImage: {
    marginRight: "auto",
    width: "2rem",
    cursor: "pointer",
  },
  sideDrawerSlider: {
    position: "fixed",
    top: 0,
    left: 0,
    height: "100vh",
    width: 300,
    zIndex: 100,
    background: "#1C232D",
  },
  sideDrawer: {
    width: "100%",
    height: "100%",
    position: "relative",
    display: "flex",
    padding: "2rem",
    paddingTop: "7rem",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  drawerMenuImage: {
    position: "absolute",
    width: 25,
    height: 25,
    top: "2rem",
    right: "2rem",
    cursor: "pointer",
  },
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0,0,0,0.2)",
  },
  linkDiv: {
    display: "flex",
    alignItems: "center",
    "& img": {
      width: "1.5rem",
    },
    "& + &": {
      marginTop: "1.5rem",
    },
  },
  link: {
    marginLeft: "1rem",
    color: "white",
    transition: "color 0.5s",
    "&:hover": {
      color: "#58B63A",
    },
  },
  activeLink: {
    color: "#58B63A",
  },
  button: {
    margin: "0 0.4rem",
    padding: "0.75rem 0",
    textAlign: "center",
    width: "7rem",
    borderRadius: "5px",
    textDecoration: "none",
    transition: "background 0.5s, color 0.5s",
    fontWeight: 500,
  },
  signup: {
    color: "white",
    border: "1px solid white",
    background: "transparent",
    "&:hover": {
      background: "#58B63A",
      borderColor: "#58B63A",
    },
  },
}));

const links = [
  {
    link: "/admin",
    name: "Dashboard",
  },
  {
    link: "/admin/code",
    name: "Generate Code",
  },
  {
    link: "/admin/phone",
    name: "Lock/Unlock Phone",
  },
];

const Navbar = () => {
  const classes = useStyles();

  const [openMenu, setOpenMenu] = useState(false);
  const dispatch = useDispatch();

  return (
    <div className={classes.navbar}>
      <Container className={classes.navbarCont}>
        {/* <NavLink to="/" className={classes.brandLogoLink}>
          <img
            src={require("../../Assets/moreLogo.png").default}
            alt="Brand Logo"
          />
        </NavLink> */}

        <img
          className={classes.menuImage}
          src={require("../../Assets/admin/hamburger.svg").default}
          alt=""
          onClick={() => setOpenMenu(true)}
        />

        <motion.div
          initial={{ left: -300 }}
          animate={{ left: openMenu ? 0 : -300 }}
          exit={{ left: 300 }}
          className={classes.sideDrawerSlider}
        >
          <div className={classes.sideDrawer}>
            <svg
              className={classes.drawerMenuImage}
              onClick={() => setOpenMenu(false)}
            >
              <path strokeWidth="3px" stroke="white" d="M 0,0 25,25" />
              <path strokeWidth="3px" stroke="white" d="M 0,25 25,0" />
            </svg>

            {links.map((link, i) => (
              <div key={i} className={classes.linkDiv}>
                <img src={linkStartImg} alt="" />
                <NavLink
                  exact
                  onClick={() => setOpenMenu(false)}
                  className={classes.link}
                  to={link.link}
                  activeClassName={classes.activeLink}
                >
                  {link.name}
                </NavLink>
              </div>
            ))}
          </div>
        </motion.div>
        {openMenu && (
          <div
            className={classes.backdrop}
            onClick={() => setOpenMenu(false)}
          ></div>
        )}

        {/* <div
          style={{
            height: "3rem",
            width: "3rem",
            background: "white",
            borderRadius: "100%",
          }}
        ></div> */}

        <p
          className={`${classes.button} ${classes.signup}`}
          onClick={() => dispatch(logout())}
        >
          Log Out
        </p>
      </Container>
    </div>
  );
};

export default Navbar;
