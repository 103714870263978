import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { NavLink } from "react-router-dom";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { Container } from "./UI/styledItems";
import InstagramIcon from "@material-ui/icons/Instagram";

const useStyles = makeStyles((theme) => ({
  footer: {
    fontFamily: "'Poppins', sans-serif",
    background: "#272727",
    zIndex: 195,
    position: "relative",
    [theme.breakpoints.down(425)]: {
      textAlign: "center",
    },
    "& p": {
      textAlign: "left",
      fontSize: "1.1rem",
      lineHeight: 1.5,
      fontWeight: 500,
      margin: 0,
      color: "white",
      [theme.breakpoints.down(670)]: {
        fontSize: "1.05rem",
      },
      [theme.breakpoints.down(425)]: {
        textAlign: "center",
      },
      [theme.breakpoints.down(400)]: {
        fontSize: "0.98rem",
      },
    },
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "3fr 3fr 3fr 3fr",
    columnGap: "5rem",
    [theme.breakpoints.down(1300)]: {
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.down(950)]: {
      gridTemplateColumns: "1fr",
    },
  },
  firstDiv: {
    "& > p": {
      marginTop: "2.5rem",
      [theme.breakpoints.down(1300)]: {
        marginTop: "1.3rem",
      },
    },
  },
  mediaHandles: {
    display: "flex",
    marginTop: "2.2rem",
    "& a": {
      marginRight: "1rem",
    },
    [theme.breakpoints.down(425)]: {
      "& a:first-child": {
        marginLeft: "auto",
      },
      "& a:last-child": {
        marginRight: "auto",
      },
    },
    "& a > div": {
      display: "flex",
      borderRadius: "100%",
      background: "white",
      width: "3rem",
      height: "3rem",
      [theme.breakpoints.down(600)]: {
        width: "2.5rem",
        height: "2.5rem",
      },
      [theme.breakpoints.down(400)]: {
        width: "2rem",
        height: "2rem",
      },
    },
    "& a > div img": {
      height: "40%",
      margin: "auto",
    },
    "& a > div svg": {
      height: "45%",
      margin: "auto",
      color: "#272727",
    },
  },
  brandLogo: {
    width: "14rem",
    marginLeft: "-1.5rem",
    marginRight: "auto",
    [theme.breakpoints.down(425)]: {
      width: "12rem",
      marginLeft: 8,
    },
  },
  contactDiv: {
    paddingTop: "0.5rem",
    color: "white",
    "& > div": {
      display: "flex",
      margin: "1.2rem 0",
    },
    "& svg": {
      marginRight: "0.8rem",
    },
    "& p": {
      marginTop: "-2px",
      "& a": {
        color: "inherit",
        textDecoration: "none",
      },
    },
    [theme.breakpoints.down(425)]: {
      "& svg": {
        marginLeft: "auto",
      },
      "& p": {
        marginRight: "auto",
      },
    },
    "& h4": {
      fontSize: "1.6rem",
      margin: 0,
      marginBottom: "3rem",
      [theme.breakpoints.down(1300)]: {
        marginBottom: "1.5rem",
      },
      [theme.breakpoints.down(950)]: {
        marginTop: "4.25rem",
      },
    },
  },
  linkDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    // paddingTop: "4.25rem",
    "& h4": {
      fontSize: "1.6rem",
      margin: 0,
      color: '#fff',
      marginBottom: "3rem",
      [theme.breakpoints.down(1300)]: {
        marginBottom: "1.5rem",
      },
      [theme.breakpoints.down(950)]: {
        marginTop: "4.25rem",
      },
    },
    [theme.breakpoints.down(425)]: {
      alignItems: "center",
    },
  },
  link: {
    color: "white",
    textDecoration: "none",
    transition: "color 0.5s",
    fontSize: "1.1rem",
    fontWeight: 500,
    margin: "0.5rem 0",
    "&:hover": {
      color: "#58B63A",
    },
    [theme.breakpoints.down(670)]: {
      fontSize: "1.05rem",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "0.98rem",
    },
  },
  activeLink: {
    color: "#58B63A",
  },
}));

const Footer = () => {
  const classes = useStyles();

  const mediaHandles = [
    {
      image: require("../Assets/media/facebook.svg").default,
      link: "https://www.facebook.com/More-and-High-Technologies-PvtLtd-106339631561879/",
      alt: "facebook handle link",
    },
    {
      image: require("../Assets/media/linkedin.svg").default,
      link: "https://www.linkedin.com/company/more-and-high-technologies-pvt-ltd/",
      alt: "linkedIn handle link",
    },
  ];

  return (
    <div className={classes.footer}>
     
      {/* <Box sx={{ width: '100%', height: 'auto', background: '#272727', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography>Developed By </Typography>  <a href="https://www.varlyq.com/" target="_blank" style={{ color: '#fff', margin: ' 10px' }}> <Typography>Varlyq Technologies</Typography></a>
      </Box> */}
    </div>
  );
};

export default Footer;
